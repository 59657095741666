import styled from "styled-components";
import { TypographyElementStyles } from "../../types";

type TypographyStylesProps = {
  styles: TypographyElementStyles;
};

export const TypographySpan = styled.span<TypographyStylesProps>`
  font-size: ${(props) => props.styles.size};
  color: ${(props) => props.styles.color};
  font-weight: ${(props) => props.styles.weight};
  font-style: ${(props) => props.styles.style};
  width: ${(props) => props.styles.width};
  font-family: ${(props) => props.styles.fontFamily};
  overflow-wrap: anywhere;
`;

export const TypographyDiv = styled.div<TypographyStylesProps>`
  font-size: ${(props) => props.styles.size};
  color: ${(props) => props.styles.color};
  font-weight: ${(props) => props.styles.weight};
  font-style: ${(props) => props.styles.style};
  width: ${(props) => props.styles.width};
  font-family: ${(props) => props.styles.fontFamily};
  overflow-wrap: anywhere;
`;

export const TypographyParagraph = styled.p<TypographyStylesProps>`
  font-size: ${(props) => props.styles.size};
  color: ${(props) => props.styles.color};
  font-weight: ${(props) => props.styles.weight};
  font-style: ${(props) => props.styles.style};
  width: ${(props) => props.styles.width};
  font-family: ${(props) => props.styles.fontFamily};
  overflow-wrap: anywhere;
`;

export const TypographyH1 = styled.h1<TypographyStylesProps>`
  font-size: ${(props) => props.styles.size};
  color: ${(props) => props.styles.color};
  font-weight: ${(props) => props.styles.weight};
  font-style: ${(props) => props.styles.style};
  width: ${(props) => props.styles.width};
  font-family: ${(props) => props.styles.fontFamily};
  overflow-wrap: anywhere;
`;

export const TypographyH2 = styled.h2<TypographyStylesProps>`
  font-size: ${(props) => props.styles.size};
  color: ${(props) => props.styles.color};
  font-weight: ${(props) => props.styles.weight};
  font-style: ${(props) => props.styles.style};
  width: ${(props) => props.styles.width};
  font-family: ${(props) => props.styles.fontFamily};
  overflow-wrap: anywhere;
`;

export const TypographyH3 = styled.h3<TypographyStylesProps>`
  font-size: ${(props) => props.styles.size};
  color: ${(props) => props.styles.color};
  font-weight: ${(props) => props.styles.weight};
  font-style: ${(props) => props.styles.style};
  width: ${(props) => props.styles.width};
  font-family: ${(props) => props.styles.fontFamily};
  overflow-wrap: anywhere;
`;

export const TypographyH4 = styled.h4<TypographyStylesProps>`
  font-size: ${(props) => props.styles.size};
  color: ${(props) => props.styles.color};
  font-weight: ${(props) => props.styles.weight};
  font-style: ${(props) => props.styles.style};
  width: ${(props) => props.styles.width};
  font-family: ${(props) => props.styles.fontFamily};
  overflow-wrap: anywhere;
`;

export const TypographyH5 = styled.h5<TypographyStylesProps>`
  font-size: ${(props) => props.styles.size};
  color: ${(props) => props.styles.color};
  font-weight: ${(props) => props.styles.weight};
  font-style: ${(props) => props.styles.style};
  width: ${(props) => props.styles.width};
  font-family: ${(props) => props.styles.fontFamily};
  overflow-wrap: anywhere;
`;

export const TypographyH6 = styled.h6<TypographyStylesProps>`
  font-size: ${(props) => props.styles.size};
  color: ${(props) => props.styles.color};
  font-weight: ${(props) => props.styles.weight};
  font-style: ${(props) => props.styles.style};
  width: ${(props) => props.styles.width};
  font-family: ${(props) => props.styles.fontFamily};
  overflow-wrap: anywhere;
`;
