"use client";

import TeamBackground from "@/components/background/TeamBackground";
import { CSRProtected } from "@/components/CSRProtected";
import { Paper, Typography } from "best-common-react";
import styled from "styled-components";

const MlbLogo = styled.img`
  height: 80px;
  align-self: center;
  justify-self: center;
`;

const PaperContainer = styled.div`
  z-index: 1051;
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 1024px;
  width: 100%;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
`;

const Home = () => {
  return (
    <CSRProtected roles={["BOC", "TEAM", "CANDIDATE"]}>
      <TeamBackground />
      <PaperContainer>
        <Paper>
          <div className="d-flex flex-column h-100 w-100">
            <Typography variant="h4" className="mb-3">
              Welcome to MLB Select!
            </Typography>
            <Typography variant="h6" className="mb-3">
              MLB Select is a tool used by all 30 MLB Clubs and the office of the Commissioner to recommend and identify
              women and diverse candidates for front office and baseball operations career opportunities.
            </Typography>
            <Typography variant="h6" className="mb-3">
              Major League Baseball is committed to creating a diverse and inclusive work environment that fosters
              creativity, engagement, and development of diverse talent.
            </Typography>
            <MlbLogo src="https://www.mlbstatic.com/team-logos/league-on-light/1.svg" alt="MLB Logo" />
          </div>
        </Paper>
      </PaperContainer>
    </CSRProtected>
  );
};

export default Home;
