import clsx from "clsx";
import React from "react";
import { Animate } from "react-simple-animate";
import { useTheme } from "../../hooks";
import { PaperThemeType } from "../../types";
import { Icon } from "../icon";
import { PaperStyled } from "./PaperStyles";

type PaperShowType = {
  visible: boolean;
  setShow: (value: boolean) => void;
};

type PaperProps = {
  animate?: boolean;
  animateDuration?: number;
  show?: PaperShowType;
  className?: string;
  styles?: PaperThemeType;
};

const Paper: React.FC<React.PropsWithChildren<PaperProps>> = ({
  children,
  show,
  animate = false,
  animateDuration = 1000,
  className = "",
  styles = {},
}) => {
  const { Theme } = useTheme();
  const StylesOverride: PaperThemeType = { ...Theme.paper, ...styles };
  const isVisible: boolean = !show || !!show?.visible;

  const Content = (
    <PaperStyled className={clsx("px-3 py-2", className)} styles={StylesOverride}>
      {!!show && (
        <div className="float-end ms-2">
          <Icon iconName="fa-times-circle" onClick={() => show.setShow(false)} />
        </div>
      )}
      {children}
    </PaperStyled>
  );

  return isVisible ? (
    animate ? (
      <Animate play start={{ width: 0 }} end={{ width: "100%" }} duration={animateDuration / 1000}>
        {Content}
      </Animate>
    ) : (
      Content
    )
  ) : null;
};

export default Paper;
