/**
 * shuffle an array
 * @param array the array to shuffle
 * @returns returns the array shuffled
 */
export const shuffleArray = <T>(array: T[]) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

/**
 * Function to filter unique objects based on a property
 *
 * @param list the list to be filtered
 * @param property unique property filter
 * */
type FilterUniqueObjects = <T>(list: T[], property: keyof T) => T[];
export const filterUniqueObjects: FilterUniqueObjects = <T, K>(list: T[], property: keyof T) => {
  const uniqueValues = new Set<K>();
  return list.filter(obj => {
    const propertyValue = obj[property] as K;
    if (!uniqueValues.has(propertyValue)) {
      uniqueValues.add(propertyValue);
      return true;
    }
    return false;
  });
};
