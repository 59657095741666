import { shuffleArray } from "@/utils/array";
import styled from "styled-components";

const TeamIds = [
  108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120, 121, 133, 134, 135, 136, 137, 138, 139, 140, 141,
  142, 143, 144, 145, 146, 147, 158
];

const BGHolder = styled.div`
  position: fixed;
  width: 100vw;
  margin-top: 20px;
  height: calc(100vh - 20px);
  overflow: hidden;
  left: 0;
  top: 0;
  z-index: 1050;
`;

const LogoHolder = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20vw;
  height: 16vh;
  opacity: 0.1;
`;

const Image = styled.img`
  width: 75%;
  height: 75%;
`;

interface LogoProps {
  id: number;
}

const Logo = ({ id }: LogoProps) => (
  <LogoHolder>
    <Image alt="team logo" src={`https://www.mlbstatic.com/team-logos/${id}.svg`} />
  </LogoHolder>
);

const BgItem = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const TeamBackground = () => (
  <BGHolder>
    <BgItem>
      {shuffleArray(TeamIds).map(id => (
        <Logo key={id} id={id} />
      ))}
    </BgItem>
  </BGHolder>
);

export default TeamBackground;
