import styled from "styled-components";
import { PaperThemeType } from "../../types";

type PaperStylesProps = {
  styles: PaperThemeType;
};

export const PaperStyled = styled.div<PaperStylesProps>`
  border: 1px solid ${(props) => props.styles.borderColor};
  background: ${(props) => props.styles.background};
`;
