"use client";

import { useUser } from "@/contexts/UserContext";
import { SelectRole } from "@/types/user";
import { useRouter } from "next/navigation";
import React, { useEffect } from "react";

export interface ProtectedProps {
  roles?: SelectRole[];
  redirectLink?: string;
  children: React.ReactNode;
}

/**
 * a component to protect client-side rendered pages
 */
export const CSRProtected = ({ children, roles = [], redirectLink = "/" }: ProtectedProps) => {
  // hooks
  const router = useRouter();
  const { loggedIn, role, initializing } = useUser();

  // constants
  const cannotView = !loggedIn || !role || (roles.length && !roles.includes(role));

  // effects
  useEffect(() => {
    if (cannotView && !initializing) {
      router.push(redirectLink);
    }
  }, [cannotView, initializing, router, redirectLink]);

  return cannotView ? null : <>{children}</>;
};
